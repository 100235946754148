import cookies from 'cookies.js';
import http from 'http.js';

import {capitaliseFirstLetter} from 'utils/capitalise.js';

/**
 * Remove this module once we fully migrate to the in-house analytics.
 */

export class Analytics {
    static url = 'https://www.google-analytics.com/collect';
    static protocolVersion = 1;
    static trackingId = 'UA-139749583-1';
    static clientId = cookies.get('gaid');
    // Only support two hit types for now
    static hitTypes = ['pageview', 'event'];

    async send(type, data = {}) {
        const {hitTypes, url} = Analytics;
        if (!hitTypes.includes(type)) {
            let types = hitTypes.slice(0, hitTypes.length - 1).join(', ');
            types = `${types} or ${hitTypes.slice(-1)}.`;
            throw Error(`'type' must be one of ${types}`);
        }

        data = this.compileData(type, data);

        await http.post({
            url,
            data,
            contentType: 'application/x-www-form-urlencoded',
        });
    }

    compileData(type, data) {
        const {clientId, protocolVersion, trackingId} = Analytics;
        const {
            practiceId,
            practitionerId,
            userId,
            consultationToken,
            href,
            title,
            eventCategory,
            eventAction,
            eventLabel,
            eventValue,
            ...args
        } = data;
        const returnData = {
            v: protocolVersion,
            tid: trackingId,
            cid: clientId,
            t: type,
            cd1: userId,
            cd2: practiceId,
            cd3: practitionerId,
            cd4: consultationToken,
            ...this[`add${capitaliseFirstLetter(type)}Parameters`]({
                href,
                title,
                eventCategory,
                eventAction,
                eventLabel,
                eventValue,
            }),
        };
        Object.values(args).forEach((arg, i) => {
            returnData[`cd${5 + i}`] = arg;
        });
        return returnData;
    }

    addPageviewParameters() {
        return {
            dl: location.href,
            dt: document.title,
        };
    }

    addEventParameters(data) {
        return {
            ec: data.eventCategory,
            ea: data.eventAction,
            el: data.eventLabel,
            ev: data.eventValue,
        };
    }
}

export default new Analytics();
