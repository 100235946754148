import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';

export class NumberInput extends React.Component {
    static propTypes = {
        ariaInvalid: PropTypes.bool,
        autoFocus: PropTypes.bool,
        decimalDigits: PropTypes.number,
        errorDescribedBy: PropTypes.string,
        handleBlur: PropTypes.func,
        handleChange: PropTypes.func.isRequired,
        handlePaste: PropTypes.func,
        inputName: PropTypes.string,
        maxValue: PropTypes.number.isRequired,
        minValue: PropTypes.number.isRequired,
        placeholder: PropTypes.string,
        style: PropTypes.object,
        value: PropTypes.string,
    };

    static defaultProps = {
        ariaInvalid: false,
        autoFocus: false,
        decimalDigits: 0,
        errorDescribedBy: null,
    };

    constructor(props) {
        super(props);
        this.blockedKeysList = ['-', '+', 'e', 'E'];
        if (!props.decimalDigits) {
            this.blockedKeysList.push('.');
        }
    }

    @autobind
    handleKeyDown(event) {
        // HTML5 number fields allow certain non-integer inputs.
        // This function stops those inputs at key press.
        const keyDown = event.key;
        if (this.blockedKeysList.indexOf(keyDown) < 0) {
            return true;
        }
        event.preventDefault();
        return false;
    }

    render() {
        // this helps bring up number keypad on iOS
        let pattern = '[0-9]*';
        let step = 1;
        let inputMode;
        if (this.props.decimalDigits) {
            pattern = '[0-9.]*';
            inputMode = 'decimal';
            step = 'any';
        }
        return (
            <input
                aria-describedby={this.props.errorDescribedBy}
                aria-invalid={this.props.ariaInvalid}
                autoFocus={this.props.autoFocus}
                data-test-id="number-input"
                inputMode={inputMode}
                max={this.props.maxValue}
                min={this.props.minValue}
                name={this.props.inputName}
                onBlur={this.props.handleBlur}
                onChange={this.props.handleChange}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.props.handleKeyUp}
                onPaste={this.props.handlePaste}
                pattern={pattern}
                placeholder={this.props.placeholder}
                ref={this.props.forwardedRef}
                step={step}
                style={this.props.style}
                type="number"
                value={this.props.value}
            />
        );
    }
}

export default React.forwardRef((props, ref) => (
    <NumberInput {...props} forwardedRef={ref} />
));
