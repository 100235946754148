import autobind from 'common/decorators/autobind.js';
import {asyncThrottle} from 'common/throttle.js';
import isInViewPort from 'common/viewport.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {escapeRegex} from 'common/regex.js';

import InputNotice from 'core/components/InputNotice.js';
import capitalise from 'utils/capitalise.js';
import Icon from 'utils/components/Icon.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';

const messages = defineMessages({
    buttonTitle: {
        id: 'questions.AutoCompleteForm.buttonTitle',
        defaultMessage: 'buttonTitle',
    },
    maxLengthError: {
        id: 'questions.AutoCompleteForm.maxLengthError',
        defaultMessage: 'maxLengthError',
    },
    nothingFound: {
        id: 'questions.AutoCompleteForm.nothingFound',
        defaultMessage: 'nothingFound',
    },
    submit: {
        id: 'questions.AutoCompleteForm.submit',
        defaultMessage: 'submit',
    },
});

export class AutoCompleteForm extends React.Component {
    static propTypes = {
        capitalisation: PropTypes.oneOf(['title', 'sentence']),
        disabledResultText: PropTypes.string,
        disabledResults: PropTypes.array,
        enableFreeText: PropTypes.bool,
        initialSearchResults: PropTypes.array,
        initialSearchValue: PropTypes.string,
        noAnswerText: PropTypes.string,
        placeholder: PropTypes.string.isRequired,
        resetAfterSubmission: PropTypes.bool,
        searchAction: PropTypes.func.isRequired,
        searchData: PropTypes.object,
        submitAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabledResults: [],
        initialSearchResults: [],
        initialSearchValue: '',
        resetAfterSubmission: false,
        searchData: {},
    };

    constructor(props) {
        super(props);

        this.selectedElem = null;
        this.isKeyUp = false;
        this.throttledSearch = asyncThrottle(this.props.searchAction, 200);

        this.state = {
            highlightedResult: null,
            initialSearchValue: props.initialSearchValue,
            focused: false,
            maxLengthError: false,
            searchResults: props.initialSearchResults,
            searchValue: props.initialSearchValue,
            searching: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.highlightedResult !== prevState.highlightedResult &&
            this.selectedElem
        ) {
            if (!isInViewPort(this.selectedElem)) {
                if (this.isKeyUp) {
                    this.selectedElem.scrollIntoView(true);
                } else {
                    this.selectedElem.scrollIntoView(false);
                }
            }
        }
    }

    @autobind
    handleFormFocus() {
        this.setState({focused: !this.state.focused});
    }

    @autobind
    handleReset() {
        this.setState({
            searchResults: [],
            searchValue: '',
        });
    }

    createFreeTextAnswer(value) {
        return {
            id: -1,
            text: capitalise(value, this.props.capitalisation),
            freeText: true,
        };
    }

    getSearchResultAnswer() {
        const {searchResults, searchValue} = this.state;
        let answer = searchResults.find(
            (result) =>
                result.text.toLowerCase() === searchValue.toLowerCase().trim(),
        );
        if (!answer) {
            answer = this.createFreeTextAnswer(searchValue);
        }
        return answer;
    }

    @autobind
    async handleSearchChange(event) {
        const {value} = event.target;
        const {searchData} = this.props;

        if (value.length) {
            if (value.length > 100) {
                this.setState({
                    initialSearchValue: value,
                    maxLengthError: true,
                    searchValue: value,
                });
                return;
            } else if (!value.trim().length) {
                this.setState({
                    initialSearchValue: value,
                    maxLengthError: false,
                    searchValue: value,
                });
                return;
            }
            this.setState({
                initialSearchValue: value,
                maxLengthError: false,
                searchValue: value,
                searching: true,
            });
            let results;
            try {
                results = await this.throttledSearch(value, searchData);
            } catch (error) {
                results = [];
            }
            if (this.props.enableFreeText) {
                // If we get a match for the free text from the synonyms, we map the result with it
                const searchText = value.toLowerCase().trim();
                /**
                 * Explanation of what a primary document is:
                 * The way our Elastic Search documents are indexed is as follows:
                 * <Primary text / Primary document> - The main document. Examples: Prescription, Headache
                 * <id> - The ID of the document
                 * <type> - What type of document it is.
                 * Values can be RFV, Symptom, Condition, Medication, etc
                 * <synonyms> - A list of synonyms for the primary text.
                 * For example, a synonym of Prescription would be Medication.
                 * Important to note
                 * - The primary text is ALSO listed in the list of synonyms.
                 * So effectively, The list `synonyms` is primary document + synonyms.
                 * This is done during index time
                 * - The primary document is always the last element of the list
                 */
                // Whether or not a primary document is being searched
                let primaryDocumentSearch = false;
                // Figuring out if any of the synonyms match the search text
                // and if the match is with a primary document
                let matchingSynonymText;
                const synonymResult = results.find((result) => {
                    const synonyms = result.synonyms;
                    if (!synonyms) {
                        return null;
                    }
                    const primaryDocumentText = synonyms.slice(-1)[0];
                    return result.synonyms.some((synonymRaw) => {
                        const synonym = synonymRaw.toLowerCase();
                        if (synonym === searchText) {
                            matchingSynonymText = synonym;
                            // note down if the match is a primary document
                            if (
                                primaryDocumentText.toLowerCase() === synonym
                            ) {
                                primaryDocumentSearch = true;
                            }
                            return true;
                        }
                    });
                });

                results = results.map((result) => ({
                    ...result,
                    text: capitalise(result.text, this.props.capitalisation),
                    freeText: false,
                }));

                // If it's not a primary document search
                if (!primaryDocumentSearch) {
                    // and we found a synonym match, map it to a result object
                    if (synonymResult) {
                        const answerFromSynonym = {
                            ...synonymResult,
                            text: matchingSynonymText,
                            freeText: false,
                        };
                        results.unshift(answerFromSynonym);
                    } else {
                        // no synonym match, add a free-text result
                        results.unshift(this.createFreeTextAnswer(value));
                    }
                }
            } else {
                results = results.map((result) => ({
                    ...result,
                    text: capitalise(result.text, this.props.capitalisation),
                }));
            }
            this.setState({
                highlightedResult: null,
                searchResults: results,
                searching: false,
            });
        } else {
            this.setState({
                highlightedResult: null,
                initialSearchValue: '',
                maxLengthError: false,
                searchResults: [],
                searchValue: '',
                searching: false,
            });
        }
    }

    @autobind
    async handleClickEvent(event) {
        const {index} = event.currentTarget.dataset;
        const answer = this.state.searchResults[index];
        await this.submitAction(answer);
    }

    @autobind
    handleMouseOver(event) {
        const {index} = event.currentTarget.dataset;
        this.setState({
            highlightedResult: this.state.searchResults[index],
        });
    }

    @autobind
    async handleKeyDown(event) {
        const supportedKeys = ['ArrowUp', 'ArrowDown', 'Enter'];
        if (!supportedKeys.includes(event.key)) {
            return;
        }
        // prevents caret from moving
        event.preventDefault();
        event.stopPropagation();

        const {highlightedResult, searchResults} = this.state;
        const enabledResults = searchResults.filter(
            (result) => !this.isDisabledResult(result),
        );
        const highlightedResultIndex = enabledResults.findIndex(
            (result) => result === highlightedResult,
        );

        if (enabledResults.length) {
            if (event.key === 'ArrowDown') {
                if (highlightedResultIndex !== enabledResults.length - 1) {
                    this.isKeyUp = false;
                    const nextResult =
                        highlightedResult === null
                            ? enabledResults[0]
                            : enabledResults[highlightedResultIndex + 1];
                    this.setState({
                        highlightedResult: nextResult,
                        searchValue: nextResult.text,
                    });
                }
            } else if (event.key === 'ArrowUp') {
                if (highlightedResultIndex > 0) {
                    this.isKeyUp = true;
                    const nextResult =
                        enabledResults[highlightedResultIndex - 1];
                    this.setState({
                        highlightedResult: nextResult,
                        searchValue: nextResult.text,
                    });
                } else {
                    this.setState((state) => ({
                        highlightedResult: null,
                        searchValue: state.initialSearchValue,
                    }));
                }
            } else if (event.key === 'Enter') {
                if (
                    (this.props.enableFreeText || highlightedResult) &&
                    !this.state.searching
                ) {
                    let answer = highlightedResult;
                    if (!answer) {
                        answer = this.getSearchResultAnswer();
                    }

                    if (!this.isDisabledResult(answer)) {
                        await this.submitAction(answer);
                    }
                }
            }
        }
    }

    highlight(result) {
        // Highlights ([]) the current search term as a substring of the
        // results e.g. searching for 'head' would be [head]ache
        const {searchValue} = this.state;
        const trimmed = escapeRegex(searchValue.trim());
        const name = capitalise(result.text, this.props.capitalisation);

        // Return early when search value is just whitespace
        // Also fixes memory leak that occurs when step is undone
        // and space is entered
        if (!trimmed) {
            return [];
        }

        const matchPositions = trimmed
            .split(/\s+/)
            .map((v) => {
                // find matches to each keyword
                const regex = new RegExp(`\\b${v}`, 'ig');
                const ret = [];
                let match = regex.exec(name);
                while (match) {
                    ret.push({
                        start: match.index,
                        end: match.index + v.length,
                    });
                    match = regex.exec(name);
                }
                return ret;
            })
            .reduce(
                // flatten the array
                (flatten, cur) => flatten.concat(cur),
                [],
            );

        if (!matchPositions.length) {
            return [<span key={0}>{name}</span>];
        }

        const highlightPositions = matchPositions
            .sort(
                // sort the array ascending by start position
                (a, b) => a.start - b.start,
            )
            .reduce((deOverlaped, cur) => {
                // de-overlap
                if (!deOverlaped.length) {
                    return [cur];
                }
                const last = deOverlaped[deOverlaped.length - 1];
                if (last.end >= cur.start) {
                    deOverlaped.splice(-1, 1, {
                        start: last.start,
                        end: Math.max(last.end, cur.end),
                    });
                } else {
                    deOverlaped.push(cur);
                }
                return deOverlaped;
            }, []);

        const elms = highlightPositions.reduce((elms, cur, idx) => {
            if (idx === 0) {
                if (cur.start !== 0) {
                    elms.push(
                        <span key={0}>{name.substring(0, cur.start)}</span>,
                    );
                }
            } else {
                const prev = highlightPositions[idx - 1];
                if (prev.end < cur.start) {
                    elms.push(
                        <span key={prev.end}>
                            {name.substring(prev.end, cur.start)}
                        </span>,
                    );
                }
            }
            elms.push(
                <span className="highlight" key={cur.start}>
                    {name.substring(cur.start, cur.end)}
                </span>,
            );

            if (idx === highlightPositions.length - 1) {
                if (cur.end < name.length) {
                    elms.push(
                        <span key={cur.end}>{name.substring(cur.end)}</span>,
                    );
                }
            }
            return elms;
        }, []);

        return elms;
    }

    @autobind
    isDisabledResult(result) {
        return this.props.disabledResults.some((item) => {
            // A result is disabled if it is a superset of one of
            // the disabledResults.
            // E.g. {id: 1, type: 'symptom' text: 'string'} will be disabled by
            // {id: 1} or {id: 1, type: 'symptom'} but not
            // {id: 1, type: 'condition'}.
            for (const [key, value] of Object.entries(item)) {
                if (value !== result[key]) {
                    return false;
                }
            }
            return true;
        });
    }

    @autobind
    async submitNoAnswer() {
        await this.submitAction(null);
    }

    async submitAction(answer) {
        const {searchResults, searchValue} = this.state;

        const searchResultsWithDisabled = searchResults.map((result) =>
            this.isDisabledResult(result)
                ? {...result, disabled: true}
                : result,
        );

        await this.props.submitAction({
            answer,
            searchResults: searchResultsWithDisabled,
            searchValue,
        });
        if (this.props.resetAfterSubmission) {
            this.handleReset();
        }
    }

    getSubmitButtonAnswer() {
        return this.state.highlightedResult || this.getSearchResultAnswer();
    }

    @autobind
    handleSubmit(event) {
        event.preventDefault();
        let answer = this.state.highlightedResult;
        if (!answer) {
            answer = this.getSearchResultAnswer();
        }
        this.submitAction(answer);
    }

    @autobind
    handleMouseOut() {
        this.setState({highlightedResult: null});
    }

    generateUniqueResultKey(result) {
        const resultKey = [];
        const excludedKeys = ['text', 'freeText'];
        Object.keys(result)
            .filter((key) => !excludedKeys.includes(key))
            .sort()
            .forEach((key) => {
                resultKey.push(result[key]);
            });
        return resultKey.join('-');
    }

    renderResults() {
        if (this.state.searchResults.length) {
            return this.state.searchResults.map(this.renderResult);
        }
        return this.renderNoResult();
    }

    @autobind
    renderResult(result, index) {
        let resultList = this.highlight(result, this.state.searchValue);
        const selected = this.state.highlightedResult === result;
        const disabled = this.isDisabledResult(result);
        if (disabled && this.props.disabledResultText) {
            resultList = resultList.concat(' ', this.props.disabledResultText);
        }
        const key = result.text || this.generateUniqueResultKey(result);
        return (
            <label
                aria-current={selected}
                aria-disabled={disabled}
                data-index={index}
                data-test-id="autocomplete-result"
                key={`${key}-${index}`}
                onMouseOver={this.handleMouseOver}
                ref={(elem) => {
                    if (selected) {
                        this.selectedElem = elem;
                    }
                }}
            >
                <input
                    checked={selected}
                    data-index={index}
                    disabled={disabled}
                    onClick={this.handleClickEvent}
                    readOnly={true}
                    type="radio"
                />
                <Icon name="IconRadio" />
                <span className="answer">{resultList.map((v) => v)}</span>
            </label>
        );
    }

    renderNoResult() {
        return (
            <p className="no-results">
                {this.props.intl.formatMessage(messages.nothingFound)}
            </p>
        );
    }

    renderInputControls() {
        const {searchValue, searching} = this.state;
        if (!searchValue) {
            return <Icon name="IconSearch" />;
        } else if (this.props.enableFreeText && !searching) {
            return (
                <AnswerSubmitButton
                    buttonText={this.props.intl.formatMessage(messages.submit)}
                    dataTestId="autocomplete-submit-button"
                    isDisabled={this.isDisabledResult(
                        this.getSubmitButtonAnswer(),
                    )}
                    submitAnswers={this.handleSubmit}
                />
            );
        } else {
            return (
                <button
                    data-test-id="autocomplete-clear-button"
                    title={this.props.intl.formatMessage(messages.buttonTitle)}
                    type="reset"
                >
                    <Icon name="IconReset" />
                </button>
            );
        }
    }

    render() {
        const showResults =
            this.state.searchValue &&
            !this.state.maxLengthError &&
            (!this.state.searching || !!this.state.searchResults.length);
        return (
            <React.Fragment>
                <form
                    aria-busy={this.state.searching}
                    className="search"
                    data-test-id={'autocomplete-form'}
                    onReset={this.handleReset}
                >
                    <label
                        aria-current={this.state.focused}
                        className="search-field"
                    >
                        {this.state.maxLengthError && (
                            <InputNotice
                                errorId="max-length-error"
                                message={this.props.intl.formatMessage(
                                    messages.maxLengthError,
                                )}
                            />
                        )}
                        <input
                            autoCapitalize="none"
                            autoComplete="off"
                            autoCorrect="off"
                            data-test-id={'autocomplete-field'}
                            inputMode="search"
                            onBlur={this.handleFormFocus}
                            onChange={this.handleSearchChange}
                            onFocus={this.handleFormFocus}
                            onKeyDown={this.handleKeyDown}
                            placeholder={this.props.placeholder}
                            spellCheck="false"
                            type="search"
                            value={this.state.searchValue}
                        />
                        {this.renderInputControls()}
                    </label>
                    {showResults && (
                        <nav
                            className="options"
                            data-test-id="autocomplete-results"
                            onMouseOut={this.handleMouseOut}
                        >
                            {this.renderResults()}
                        </nav>
                    )}
                </form>
                {this.props.noAnswerText && !this.state.searchValue && (
                    <AnswerSubmitButton
                        buttonText={this.props.noAnswerText}
                        dataTestId="autocomplete-cancel-button"
                        isSecondary={true}
                        submitAnswers={this.submitNoAnswer}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default injectIntl(AutoCompleteForm);
